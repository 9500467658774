import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Header: React.FC = () => {
  // State for managing the mobile menu visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle the mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close menu when clicking outside or after selecting an item
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="bg-gradient-to-r from-purple-500 via-indigo-500 to-blue-500 text-white shadow-lg sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
        {/* Logo with Animation */}
        <motion.div
          className="text-2xl font-bold tracking-wider"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, type: "spring", stiffness: 100 }}
        >
          <Link to="/">AudMedia</Link>
        </motion.div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>

        {/* Desktop Navigation Links */}
        <motion.nav
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.3 }}
          className="hidden lg:flex space-x-8 text-lg"
        >
          <ul className="flex space-x-8 text-lg">
            <li>
              <Link
                to="/"
                className="hover:text-gray-300 transition duration-300 ease-in-out"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className="hover:text-gray-300 transition duration-300 ease-in-out"
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="hover:text-gray-300 transition duration-300 ease-in-out"
              >
                Contact
              </Link>
            </li>
          </ul>
        </motion.nav>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <>
          {/* Full-screen overlay */}
          <div
            onClick={closeMenu} // Close menu when clicking outside
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
          />

          {/* Mobile Menu Content */}
          <motion.div
            className="fixed top-0 left-0 w-full h-screen bg-gradient-to-b from-purple-600 to-indigo-700 z-50 p-6 flex flex-col items-center justify-center"
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, type: "spring", stiffness: 100 }}
          >
            <ul className="space-y-8 text-2xl font-semibold text-white">
              <li>
                <Link
                  to="/"
                  className="hover:text-yellow-300 transition-all duration-300 ease-in-out transform hover:scale-105"
                  onClick={closeMenu}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="hover:text-yellow-300 transition-all duration-300 ease-in-out transform hover:scale-105"
                  onClick={closeMenu}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="hover:text-yellow-300 transition-all duration-300 ease-in-out transform hover:scale-105"
                  onClick={closeMenu}
                >
                  Contact
                </Link>
              </li>
            </ul>

            {/* Social Icons */}
            <div className="mt-10 flex space-x-4">
              {/* Instagram Icon */}
              <a
                href="https://www.instagram.com/audmedia_"
                className="text-white hover:text-yellow-300 transition-all duration-300"
              >
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M7.5 2h9A5.5 5.5 0 0122 7.5v9a5.5 5.5 0 01-5.5 5.5h-9A5.5 5.5 0 012 16.5v-9A5.5 5.5 0 017.5 2zm0-2A7.5 7.5 0 000 7.5v9A7.5 7.5 0 007.5 24h9a7.5 7.5 0 007.5-7.5v-9A7.5 7.5 0 0016.5 0h-9zm4.75 7.75A5.25 5.25 0 1112 18a5.25 5.25 0 010-10.5zm0 1.5A3.75 3.75 0 1012 16.25a3.75 3.75 0 000-7.5zM19 5.75a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z" />
                </svg>
              </a>

              {/* WhatsApp Icon */}
              <a
                href="https://wa.me/918129728804"
                className="text-white hover:text-yellow-300 transition-all duration-300"
              >
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M20.52 3.48A11.88 11.88 0 0012 0a12 12 0 00-9.79 18.5L.41 24l5.71-1.51A12 12 0 1012 0v.04A12 12 0 0020.52 3.48zM12 22.8A10.8 10.8 0 1118.71 5.29 10.8 10.8 0 0112 22.8zM17.25 14.9c-.18-.09-1.09-.54-1.25-.6-.16-.06-.28-.09-.4.09-.12.18-.46.6-.56.73-.1.12-.21.14-.39.05-.18-.09-.76-.28-1.44-.89-.53-.47-.89-1.05-1-1.23-.1-.18-.01-.28.08-.37.08-.08.18-.2.27-.3.1-.12.12-.18.18-.3.06-.12.03-.22-.01-.3-.05-.09-.44-1.08-.6-1.48-.16-.39-.33-.34-.48-.34-.13 0-.28-.01-.43-.01-.15 0-.38.05-.59.26-.2.21-.77.76-.77 1.85 0 1.08.79 2.13.9 2.28.11.15 1.56 2.44 3.77 3.31.52.22.92.35 1.23.44.52.16.99.14 1.36.09.41-.06 1.27-.52 1.45-1.03.18-.51.18-.95.12-1.03-.06-.09-.16-.13-.34-.22z" />
                </svg>
              </a>

              {/* Email Icon */}
              <a
                href="mailto:arjun@audmedia.in"
                className="text-white hover:text-yellow-300 transition-all duration-300"
              >
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2v.01L12 13 4 6.01V6h16zM4 18v-9.99l7.5 4.99 7.5-4.99V18H4z" />
                </svg>
              </a>
            </div>
          </motion.div>
        </>
      )}
    </header>
  );
};

export default Header;
