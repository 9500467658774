import React from "react";
import { motion } from "framer-motion";
import { FaVideo, FaPaintBrush, FaPenNib, FaPhotoVideo, FaUsers, FaLaptopCode } from "react-icons/fa";

const services = [
  {
    title: "Video Editing",
    description: "Professional video editing services for all your needs.",
    icon: <FaVideo />,
  },
  {
    title: "Thumbnail Design",
    description: "Custom thumbnails to make your content stand out.",
    icon: <FaPhotoVideo />,
  },
  {
    title: "Logo Design",
    description: "Unique logo designs that reflect your brand identity.",
    icon: <FaPenNib />,
  },
  {
    title: "Content Creation",
    description: "Engaging content tailored to your audience.",
    icon: <FaPaintBrush />,
  },
  {
    title: "Social Media Management",
    description: "Boost your online presence with expert social media strategies.",
    icon: <FaUsers />,
  },
  {
    title: "Web Application Development",
    description: "Beautiful and functional websites for your business.",
    icon: <FaLaptopCode />,
  },
];

const Services: React.FC = () => {
  return (
    <section className="bg-gradient-to-b from-gray-100 to-gray-200 text-black py-20">
      <div className="container mx-auto px-6">
        <h2 className="text-5xl font-extrabold text-center mb-12 text-indigo-700">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="bg-white shadow-lg rounded-lg p-8 flex flex-col items-center hover:shadow-2xl transition-shadow duration-500 transform hover:scale-105"
              whileHover={{ scale: 1.1, rotate: 3 }}
              whileTap={{ scale: 0.95 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <div className="text-indigo-600 mb-6 text-6xl">
                {service.icon}
              </div>
              <h3 className="text-2xl font-semibold mb-4">{service.title}</h3>
              <p className="text-gray-700 text-center">{service.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
