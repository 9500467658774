import React from 'react';
import { FaInstagram, FaEnvelope } from 'react-icons/fa'; // Adding social media icons

const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto px-6 text-center">
        <div className="mb-4 flex justify-center space-x-6">
          {/* Social Media Links */}
          <a
            href="mailto:arjun@audmedia.in"
            className="text-white hover:text-blue-500 transition-colors duration-300"
            aria-label="Email"
          >
            <FaEnvelope size={24} />
          </a>
          <a
            href="https://www.instagram.com/audmedia_/"
            className="text-white hover:text-pink-500 transition-colors duration-300"
            aria-label="Instagram"
          >
            <FaInstagram size={24} />
          </a>
        </div>
        <p className="text-sm">© {new Date().getFullYear()} AudMedia. All Rights Reserved.</p>
        <p className="text-xs text-gray-400 mt-2">
          Designed by Arjun Uday | <a href="mailto:arjun@audmedia.in" className="hover:underline text-gray-400">Contact Us</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
