import React from "react";
import { FaInstagram, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Contact: React.FC = () => {
  return (
    <section className="bg-white text-black py-20">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-12">Contact Us</h2>
        <div className="max-w-lg mx-auto bg-gray-100 p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
          <div className="mb-6">
            <p className="text-lg mb-2 font-bold text-gray-700">Name:</p>
            <p className="text-lg text-gray-800">Arjun Uday</p>
          </div>
          <div className="mb-6">
            <p className="text-lg mb-2 font-bold text-gray-700">Email:</p>
            <p className="text-lg">
              <a href="mailto:arjun@audmedia.in" className="text-blue-600 hover:underline hover:text-blue-800 transition-colors duration-200">
                arjun@audmedia.in
              </a>
            </p>
          </div>
          <div className="mb-6">
            <p className="text-lg mb-2 font-bold text-gray-700">Instagram:</p>
            <p className="text-lg">
              <a href="https://www.instagram.com/audmedia_/" className="text-pink-600 hover:underline hover:text-pink-800 flex items-center transition-colors duration-200">
                <FaInstagram className="mr-2" /> @audmedia_
              </a>
            </p>
          </div>
          <div className="mb-6">
            <p className="text-lg mb-2 font-bold text-gray-700">Location:</p>
            <p className="text-lg flex items-center">
              <FaMapMarkerAlt className="mr-2 text-red-500" /> Kochi, India
            </p>
          </div>
          <div className="text-center mt-6">
            <p className="text-lg text-gray-700">Feel free to reach out for any inquiries or project discussions!</p>
            <div className="mt-4 flex justify-center space-x-6">
              <a href="mailto:arjun@audmedia.in" className="text-blue-500 hover:text-blue-700 transition-transform duration-200 transform hover:scale-110">
                <FaEnvelope size={28} />
              </a>
              <a href="https://www.instagram.com/audmedia_/" className="text-pink-500 hover:text-pink-700 transition-transform duration-200 transform hover:scale-110">
                <FaInstagram size={28} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
