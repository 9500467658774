import React from "react";
import { motion } from "framer-motion";

const Home: React.FC = () => {
  return (
    <section className="bg-gradient-to-r from-purple-500 via-indigo-500 to-blue-500 text-white h-screen flex flex-col items-center justify-center space-y-8 relative overflow-hidden">
      {/* Background Animation */}
      <div className="absolute inset-0 bg-gradient-to-r from-purple-500 via-indigo-500 to-blue-500 opacity-30 animate-pulse"></div>

      {/* Title with Animation */}
      <motion.h1
        className="text-5xl md:text-7xl font-bold text-center"
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, type: "spring", stiffness: 100 }}
      >
        Welcome to AudMedia
      </motion.h1>

      {/* Description with Animation */}
      <motion.p
        className="text-lg text-center max-w-2xl"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1, delay: 0.3 }}
      >
        We are a professional agency that specializes in video editing,
        thumbnail design, logo creation, and more. Let us help you build your
        brand and online presence.
      </motion.p>

      {/* Explore Button with Hover Effect */}
      <motion.a
        href="/services"
        className="text-lg bg-white text-black py-3 px-6 rounded-full font-semibold hover:bg-gray-200 transition duration-300 ease-in-out transform hover:scale-105"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        Explore Our Services
      </motion.a>

      {/* Optional: Add some subtle floating animation */}
      <motion.div
        className="absolute bottom-10 left-1/2 transform -translate-x-1/2 text-center text-xl text-gray-300"
        animate={{ y: [0, 10, 0] }}
        transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
      >
        Scroll down to discover more
      </motion.div>
    </section>
  );
};

export default Home;
